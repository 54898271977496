const debug = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: "http://127.0.0.1:5001",
    frontend_url: "http://127.0.0.1:3000/",
    dashboard_url: "http://127.0.0.1:3000/",
    app_proxy_path: "/apps/shopeaks-dev/",
}

const dev = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api-dev.payli.in`,
    frontend_url: `https://l-dev.shopeaks.com/`,
    dashboard_url: `https://dev.payli.in/`,
    app_proxy_path: "/apps/shopeaks-dev/",
}

const staging = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api-staging.payli.in`,
    frontend_url: `https://l-staging.shopeaks.com/`,
    dashboard_url: `https://staging.payli.in/`,
    app_proxy_path: "/apps/shopeaks-staging/",
}

const prod = {
    terminal_name: "paydin",
    response_language: "english",
    base_url: `https://api.payli.in`,
    frontend_url: `https://l.shopeaks.com/`,
    dashboard_url: `https://payli.in/`,
    app_proxy_path: "/apps/shopeaks/",
}

export const environment = (() => {
    switch (process.env.REACT_APP_STAGE) {
        case "debug":
            return debug;
        case "dev":
            return dev;
        case "staging":
            return staging;
        case "prod":
            return prod;
        default:
            return null;
    }
})()