import { getLinkOrders } from 'api/links/links'
import LinkOrdersTableHeaderView from 'components/LinkOrdersTableHeaderView/LinkOrdersTableHeaderView'
import LinkOrdersTableRowView from 'components/LinkOrdersTableRowView/LinkOrdersTableRowView'
import PaydinTable from 'components/PaydinTable/PaydinTable'
import { MOBILE_ORDERS_TABLE_ROW_HEIGHT_PX, MOBILE_PRODUCTS_TABLE_ROW_HEIGHT_PX, ORDERS_TABLE_HEADERS_IDS, ORDERS_TABLE_ROWS_PER_PAGE, ORDERS_TABLE_ROW_HEIGHT_PX, PRODUCTS_TABLE_ROW_HEIGHT_PX } from 'constants/general.constants'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { extractOrderRowData } from 'utils/dataUtils'
import './LinkOrdersTable.css'
import { useTranslation } from 'react-i18next'

/**
 * Represents an orders table for a specific link.
 * @param {string} linkId - The id of the link 
 * @param {number} salesCount - The total number of sale made with the link 
 * @param {array} removedColumnIds - An array of column IDs that are required to remove from display
 */
export default function LinkOrdersTable({
    linkId,
    salesCount,
    removedColumnIds = [],
    allowOrderDetails = false
}) {
    const { t } = useTranslation()
    
    function createData(id, createdAt, productsCount, totalPrice, status, url, currency, totalProductsCount, allowOrderDetails) {
        const data = {
            [ORDERS_TABLE_HEADERS_IDS.DATE]: createdAt,
            [ORDERS_TABLE_HEADERS_IDS.TOTAL_PRODUCTS_COUNT]: productsCount,
            [ORDERS_TABLE_HEADERS_IDS.TOTAL_PRICE]: totalPrice,
            [ORDERS_TABLE_HEADERS_IDS.STATUS]: status,
            [ORDERS_TABLE_HEADERS_IDS.ACTIONS]: url,
            currency,
            id,
            linkId,
            totalProductsCount,
            allowOrderDetails,
        }

        if (removedColumnIds.length > 0) {
            Object.keys(data).forEach(columnId => {
                if (removedColumnIds.includes(columnId)) {
                    delete data[columnId]
                }
            })
        }

        return data
    }

    function normalizeTableData(items) {
        const normalizedData = []

        for (const item of items) {
            normalizedData.push(
                createData(
                    item?.id,
                    item?.createdAt,
                    item?.totalProductsCount,
                    item?.totalPrice,
                    item?.status,
                    item?.url,
                    item?.currency,
                    item?.totalProductsCount,
                    allowOrderDetails,
                )
            )
        }

        return normalizedData
    }

    function getOrdersData(count, skip, onSuccess = () => { }, onFailure = () => { }) {
        getLinkOrders(linkId, count, skip)
            .then(orders => {
                onSuccess(normalizeTableData(orders ? orders.map(order => extractOrderRowData(order)) : []))
            })
            .catch(error => {
                console.log(error)
                onFailure(error)
            })
    }
    
    return (
        <PaydinTable
            visibleRowsCount={ORDERS_TABLE_ROWS_PER_PAGE}
            borderRadius='10px'
            totalCount={salesCount}
            rowHeightPx={isMobile ? MOBILE_ORDERS_TABLE_ROW_HEIGHT_PX : ORDERS_TABLE_ROW_HEIGHT_PX}
            rowView={LinkOrdersTableRowView}
            headerView={LinkOrdersTableHeaderView}
            hideHeaderInMobile={true}
            dataGenerator={getOrdersData}
            noItemsImage={<HiOutlineShoppingBag className='orders-table-no-items-image' />}
            noItemsText={t('ORDERS_TABLE_NO_ITEMS_TO_DISPLAY_MESSAGE')}
            removedColumnIds={removedColumnIds}
        />
    )
}
