import React, { useEffect, useMemo, useState } from 'react'
import './HomePage.css'
import { useGeneralDataContext, useUserDetailsContext } from 'contexts/User'
import ImageBox from 'components/ImageBox/ImageBox'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { BiLink } from 'react-icons/bi'
import ShortsImage from 'embeddedImages/ShortsImage'
import { FiUsers } from 'react-icons/fi'
import { useHistoryContext } from 'contexts/History'
import {
    APPEARANCE_PAGE_URL,
    INFLUENCERS_PAGE_URL,
    LINKS_PAGE_URL,
    PRICING_PLANS_PAGE_URL,
    SETTINGS_PAGE_URL,
    SHORTS_PAGE_URL
} from 'constants/routes.constants'
import { ALERT_MESSAGE_TYPES, APPEARANCE_PAGE_LOGO_SECTION_ID, APPEARANCE_PAGE_THEME_SECTION_ID, CONTENT_TYPES, HOME_PAGE_DESKTOP_GET_INFLUENCERS_API_COUNT, HOME_PAGE_DESKTOP_GET_SHORTS_API_COUNT, HOME_PAGE_GET_LINKS_API_COUNT, HOME_PAGE_MOBILE_GET_INFLUENCERS_API_COUNT, HOME_PAGE_MOBILE_GET_SHORTS_API_COUNT, HOME_PAGE_RECENT_ACTIVITIES_TABS, HOME_PAGE_TASKS, HOME_PAGE_TODO_TASK_ANALYTICS_IMAGE_URL, HOME_PAGE_TODO_TASK_INSTANT_IMAGE_URL, HOME_PAGE_TODO_TASK_LOGO_IMAGE_URL, HOME_PAGE_TODO_TASK_SHIPPING_IMAGE_URL, HOME_PAGE_TODO_TASK_UTM_IMAGE_URL, LINK_TYPES, NOT_PRO_MAX_INFLUENCER_NUMBER, SETTINGS_PAGE_ANALYTICS_SECTION_ID, SETTINGS_PAGE_SHIPPING_AND_RETURN_SECTION_ID, SETTINGS_PAGE_UTM_SECTION_ID, VIEW_MODES } from 'constants/general.constants'
import { GetLinksAPI } from 'api/links/links'
import { GetInfluencers, GetInfluencersAnalytics } from 'api/influencers/influencers'
import Loader from 'components/Loader/Loader'
import LinkItem from 'components/LinkItem/LinkItem'
import ShortItem from 'components/ShortItem/ShortItem'
import InfluencerItem from 'components/InfluencerItem/InfluencerItem'
import { extractBasicInfluencerAnalytics, extractInfluencerData, extractLinkData } from 'utils/dataUtils'
import HomePageSection from 'components/HomePageSection/HomePageSection'
import MobileRecentActivitySection from 'components/MobileRecentActivitySection/MobileRecentActivitySection'
import TodoList from 'components/TodoList/TodoList'

import NoInfluencersFoundImage from 'embeddedImages/NoInfluencersFoundImage'
import NoItemsFound from 'components/NoItemsFound/NoItemsFound'
import ShopifyConnect from 'components/ShopifyConnect/ShopifyConnect'
import StoreOrdersTable from 'components/StoreOrdersTable/StoreOrdersTable'
import ContentDialog from 'dialogs/ContentDialog/ContentDialog'
import AddLInkFlow from 'components/AddLInkFlow/AddLInkFlow'
import AlertMessage from 'components/AlertMessage/AlertMessage'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FloatingButton from 'components/FloatingButton/FloatingButton'

/**
 * Represents the home page.
 */
export default function HomePage() {
    const { t } = useTranslation()
    const {
        userDetails,
        setUserDetails,
        setRecentLinks,
        setRecentShorts,
        setRecentInfluencers,
        isProFeatureAnabled,
        hasShopifyStore,
        isFreeUser,
        isFreeUserAndTrialEnded,
        isFreeUserAndTrialPastLastWeek,
    } = useUserDetailsContext()
    const {
        openFreeTrialDialog,
    } = useGeneralDataContext()
    const history = useHistoryContext()

    const taskList = useMemo(() => ([
        {
            name: HOME_PAGE_TASKS.ADD_LOGO,
            title: t('HOME_PAGE_TODO_LIST_ADD_LOGO_TASK_TITLE'),
            description: t('HOME_PAGE_TODO_LIST_ADD_LOGO_TASK_DESCRIPTION'),
            buttonText: t('HOME_PAGE_TODO_LIST_ADD_LOGO_TASK_BUTTON_TEXT'),
            onButtonClick: navigateToLogoAddition,
            image: <ImageBox className='home-page-task-image' image={HOME_PAGE_TODO_TASK_LOGO_IMAGE_URL} />,
            isDone: ![null, undefined, ''].includes(userDetails?.businessLogo)
        },
        {
            name: HOME_PAGE_TASKS.CUSTOMIZE_THEME,
            title: t('HOME_PAGE_TODO_LIST_CUSTOMIZE_THEME_TASK_TITLE'),
            description: t('HOME_PAGE_TODO_LIST_CUSTOMIZE_THEME_TASK_DESCRIPTION'),
            buttonText: t('HOME_PAGE_TODO_LIST_CUSTOMIZE_THEME_TASK_BUTTON_TEXT'),
            onButtonClick: navigateToThemeCustomization,
            image: <ImageBox className='home-page-task-image' image={HOME_PAGE_TODO_TASK_INSTANT_IMAGE_URL} />,
            isDone: !userDetails?.theme?.isInitial
        },
        {
            name: HOME_PAGE_TASKS.ADD_SHIPPING_AND_RETURN,
            title: t('HOME_PAGE_TODO_LIST_ADD_SHIPPING_AND_RETURN_TASK_TITLE'),
            description: t('HOME_PAGE_TODO_LIST_ADD_SHIPPING_AND_RETURN_TASK_DESCRIPTION'),
            buttonText: t('HOME_PAGE_TODO_LIST_ADD_SHIPPING_AND_RETURN_TASK_BUTTON_TEXT'),
            onButtonClick: navigateToShippingAndReturnURLAddition,
            image: <ImageBox className='home-page-task-image' image={HOME_PAGE_TODO_TASK_SHIPPING_IMAGE_URL} />,
            isDone: userDetails?.settings?.shippingAndReturnsUrl !== ''
        },
        {
            name: HOME_PAGE_TASKS.ADD_UTM,
            title: t('HOME_PAGE_TODO_LIST_ADD_UTM_PARAMS_TASK_TITLE'),
            description: t('HOME_PAGE_TODO_LIST_ADD_UTM_PARAMS_TASK_DESCRIPTION'),
            buttonText: t('HOME_PAGE_TODO_LIST_ADD_UTM_PARAMS_TASK_BUTTON_TEXT'),
            onButtonClick: navigateToURLParamsAddition,
            image: <ImageBox className='home-page-task-image' image={HOME_PAGE_TODO_TASK_UTM_IMAGE_URL} />,
            isDone: hasUTMParams()
        },
        {
            name: HOME_PAGE_TASKS.ADD_ANALYTICS,
            title: t('HOME_PAGE_TODO_LIST_ADD_ANALYTICS_TASK_TITLE'),
            description: t('HOME_PAGE_TODO_LIST_ADD_ANALYTICS_TASK_DESCRIPTION'),
            buttonText: t('HOME_PAGE_TODO_LIST_ADD_ANALYTICS_TASK_BUTTON_TEXT'),
            onButtonClick: navigateToAnalyticsIDsAddition,
            image: <ImageBox className='home-page-task-image' image={HOME_PAGE_TODO_TASK_ANALYTICS_IMAGE_URL} />,
            isDone: hasAnalyticsIDs()
        }
    ]), [userDetails])

    const [recentActivitiesTab, setRecentActivitiesTab] = useState(HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS)
    const [isLoadingDisplayContent, setIsLoadingDisplayContent] = useState(false)
    const [isLoadingFinished, setIsLoadingFinished] = useState({
        links: false,
        shorts: false,
        influencers: false
    })
    const [recentActivitiesItems, setRecentActivitiesItems] = useState([])
    const [mobileRecentActivitiesItems, setMobileRecentActivitiesItems] = useState({
        links: [],
        shorts: [],
        influencers: []
    })
    const [mobileRecentActivitiesLoadingState, setMobileRecentActivitiesLoadingState] = useState({
        links: false,
        shorts: false,
        influencers: false
    })
    const [isLoadingInfluencersAnalytics, setIsLoadingInfluencersAnalytics] = useState(false)
    const [influencersAnalytics, setInfluencersAnalytics] = useState([])
    const [expandedTask, setExpandedTask] = useState(taskList.find(task => !task?.isDone)?.name)
    const [contentDialogState, setContentDialogState] = useState({
        isOpen: false,
        mode: VIEW_MODES.CREATE,
        onSaveSuccess: () => { }
    })
    const [addFlowTrigger, setAddFlowTrigger] = useState(false)

    useEffect(() => {
        if (isMobile) {
            getLinks(links => {
                setMobileRecentActivitiesItems(prev => ({
                    ...prev,
                    links
                }))
            })
            getShorts(shorts => {
                setMobileRecentActivitiesItems(prev => ({
                    ...prev,
                    shorts
                }))
            })
        }
        // Get influencer anyway
        if (userDetails?.isAdmin) {
            getInfluencers(influencers => {
                setMobileRecentActivitiesItems(prev => ({
                    ...prev,
                    influencers
                }))
            })
            getInfluencersAnalytics()
        }
    }, [])

    useEffect(() => {
        if (!isMobile) {
            switch (recentActivitiesTab) {
                case HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS:
                    if (userDetails?.recentActivitiesData?.links.length > 0) {
                        setRecentActivitiesItems(userDetails?.recentActivitiesData?.links)
                        setIsLoadingFinished(prev => ({
                            ...prev,
                            links: true
                        }))
                    } else {
                        loadLinksFromServer()
                    }
                    break

                case HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS:
                    if (userDetails?.recentActivitiesData?.shorts.length > 0) {
                        setRecentActivitiesItems(userDetails?.recentActivitiesData?.shorts)
                        setIsLoadingFinished(prev => ({
                            ...prev,
                            shorts: true
                        }))
                    } else {
                        loadShortsFromServer()
                    }
                    break

                default: // influencers
                    if (userDetails?.recentActivitiesData?.influencers?.items.length > 0) {
                        setRecentActivitiesItems(userDetails?.recentActivitiesData?.influencers?.items)
                        setInfluencersAnalytics(userDetails?.recentActivitiesData?.influencers?.analytics)
                        setIsLoadingFinished(prev => ({
                            ...prev,
                            influencers: true
                        }))
                    } else {
                        loadInfluencersFromServer()
                    }
                    break
            }
        }
    }, [recentActivitiesTab])

    function loadLinksFromServer() {
        if (isMobile) {
            setMobileRecentActivitiesLoadingState(prev => ({
                ...prev,
                links: true
            }))
            getLinks(links => {
                setMobileRecentActivitiesItems(prev => ({
                    ...prev,
                    links
                }))
                setMobileRecentActivitiesLoadingState(prev => ({
                    ...prev,
                    links: false
                }))
            })
        } else {
            setRecentActivitiesItems([])
            setIsLoadingDisplayContent(true)
            getLinks(links => setRecentActivitiesItems(links))
        }
    }

    function loadShortsFromServer() {
        if (isMobile) {
            setMobileRecentActivitiesLoadingState(prev => ({
                ...prev,
                shorts: true
            }))
            getShorts(shorts => {
                setMobileRecentActivitiesItems(prev => ({
                    ...prev,
                    shorts
                }))
                setMobileRecentActivitiesLoadingState(prev => ({
                    ...prev,
                    shorts: false
                }))
            })
        }
        setRecentActivitiesItems([])
        setIsLoadingDisplayContent(true)
        getShorts(shorts => setRecentActivitiesItems(shorts))
    }

    function loadInfluencersFromServer() {
        if (isMobile) {
            getInfluencers(influencers => {
                setMobileRecentActivitiesItems(prev => ({
                    ...prev,
                    influencers
                }))
            })
            getInfluencersAnalytics()
        } else {
            setRecentActivitiesItems([])
            getInfluencers(influencers => setRecentActivitiesItems(influencers))
            getInfluencersAnalytics()
        }
    }

    function getLinks(onSuccess = () => { }) {
        setIsLoadingFinished(prev => ({
            ...prev,
            links: false
        }))
        setIsLoadingDisplayContent(true)
        setMobileRecentActivitiesLoadingState(prev => ({
            ...prev,
            links: true
        }))
        const linksCount = HOME_PAGE_GET_LINKS_API_COUNT
        GetLinksAPI(userDetails?.businessId, '', linksCount, 0, LINK_TYPES.LOOK)
            .then(data => {
                const loadedLinks = data?.links?.map(item => extractLinkData(item))
                onSuccess(loadedLinks)
                setRecentLinks(loadedLinks)
            })
            .catch(console.log)
            .finally(() => {
                setIsLoadingDisplayContent(false)
                setMobileRecentActivitiesLoadingState(prev => ({
                    ...prev,
                    links: false
                }))
                setIsLoadingFinished(prev => ({
                    ...prev,
                    links: true
                }))
            })
    }

    function getShorts(onSuccess = () => { }) {
        setIsLoadingFinished(prev => ({
            ...prev,
            shorts: false
        }))
        setIsLoadingDisplayContent(true)
        setMobileRecentActivitiesLoadingState(prev => ({
            ...prev,
            shorts: true
        }))
        const shortsCount = isMobile ? HOME_PAGE_MOBILE_GET_SHORTS_API_COUNT : HOME_PAGE_DESKTOP_GET_SHORTS_API_COUNT
        GetLinksAPI(userDetails?.businessId, '', shortsCount, 0, LINK_TYPES.SHORT)
            .then(data => {
                const loadedShorts = data?.links?.map(item => extractLinkData(item))
                onSuccess(loadedShorts)
                setRecentShorts(loadedShorts)
            })
            .catch(console.log)
            .finally(() => {
                setIsLoadingDisplayContent(false)
                setMobileRecentActivitiesLoadingState(prev => ({
                    ...prev,
                    shorts: false
                }))
                setIsLoadingFinished(prev => ({
                    ...prev,
                    shorts: true
                }))
            })
    }

    function getInfluencers(onSuccess = () => { }) {
        setIsLoadingFinished(prev => ({
            ...prev,
            influencers: false
        }))
        setIsLoadingDisplayContent(true)
        setMobileRecentActivitiesLoadingState(prev => ({
            ...prev,
            influencers: true
        }))
        GetInfluencers()
            .then(data => {
                const loadedInfluencers = data?.map(item => extractInfluencerData(item))?.filter(influencer => influencer?.isActive)?.slice(0, HOME_PAGE_DESKTOP_GET_INFLUENCERS_API_COUNT)
                onSuccess(loadedInfluencers)
                setRecentInfluencers(loadedInfluencers)
            })
            .catch(console.log)
            .finally(() => {
                setIsLoadingDisplayContent(false)
                setMobileRecentActivitiesLoadingState(prev => ({
                    ...prev,
                    influencers: false
                }))
                setIsLoadingFinished(prev => ({
                    ...prev,
                    influencers: true
                }))
            })
    }

    function getInfluencersAnalytics() {
        setIsLoadingInfluencersAnalytics(true)
        GetInfluencersAnalytics()
            .then(response => {
                const loadedInfluencersAnalytics = response?.map(influencer => extractBasicInfluencerAnalytics(influencer))
                setInfluencersAnalytics(loadedInfluencersAnalytics)
                setUserDetails(prev => ({
                    ...prev,
                    recentActivitiesData: {
                        ...prev.recentActivitiesData,
                        influencers: {
                            ...prev.recentActivitiesData.influencers,
                            analytics: loadedInfluencersAnalytics
                        }
                    }
                }))
            })
            .catch(console.log)
            .finally(() => {
                setIsLoadingInfluencersAnalytics(false)
            })
    }

    function hasUTMParams() {
        const utmParams = userDetails?.settings?.utmParams
        return (
            utmParams?.UTMSource !== '' ||
            utmParams?.UTMCampaign !== '' ||
            utmParams?.UTMMedium !== '' ||
            utmParams?.UTMTerm !== '' ||
            utmParams?.UTMContent !== ''
        )
    }

    function hasAnalyticsIDs() {
        const settings = userDetails?.settings
        return (
            settings?.facebookPixelId !== '' ||
            settings?.googleAnalyticsId !== ''
        )
    }

    function navigateToLogoAddition() {
        history.push(`${APPEARANCE_PAGE_URL}#${APPEARANCE_PAGE_LOGO_SECTION_ID}`)
    }

    function navigateToThemeCustomization() {
        history.push(`${APPEARANCE_PAGE_URL}#${APPEARANCE_PAGE_THEME_SECTION_ID}`)
    }

    function navigateToShippingAndReturnURLAddition() {
        history.push(`${SETTINGS_PAGE_URL}#${SETTINGS_PAGE_SHIPPING_AND_RETURN_SECTION_ID}`)
    }

    function navigateToURLParamsAddition() {
        history.push(`${SETTINGS_PAGE_URL}#${SETTINGS_PAGE_UTM_SECTION_ID}`)
    }

    function navigateToAnalyticsIDsAddition() {
        history.push(`${SETTINGS_PAGE_URL}#${SETTINGS_PAGE_ANALYTICS_SECTION_ID}`)
    }

    function reloadAfterCreation(tabType, loadItemsCallback = () => { }) {
        if (isMobile) {
            loadItemsCallback()
        } else {
            if (recentActivitiesTab === tabType) {
                loadItemsCallback()
            } else {
                switch (tabType) {
                    case HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS:
                        setRecentLinks([])
                        break
                    case HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS:
                        setRecentShorts([])
                        break
                    default:
                        setRecentInfluencers([])
                        break
                }
            }
        }
    }

    function createLink() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else {
            setAddFlowTrigger(true)
        }
    }

    function createShort() {
        setContentDialogState({
            isOpen: true,
            contentType: CONTENT_TYPES.SHORT,
            onSaveSuccess: () => reloadAfterCreation(HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS, loadShortsFromServer)
        })
    }

    function createInfluencer() {
        if (isFreeUserAndTrialEnded()) {
            openFreeTrialDialog()
        } else if (!isProFeatureAnabled(true) && userDetails?.recentActivitiesData?.influencers?.items.length >= NOT_PRO_MAX_INFLUENCER_NUMBER) {
            openFreeTrialDialog(true, t('NOT_PRO_TOO_MANY_INFLUENCER_DIALOG_TITLE'), t('NOT_PRO_TOO_MANY_INFLUENCER_DIALOG_SUBTITLE'))
        } else {
            setContentDialogState({
                isOpen: true,
                contentType: CONTENT_TYPES.INFLUENCER,
                onSaveSuccess: () => reloadAfterCreation(HOME_PAGE_RECENT_ACTIVITIES_TABS.INFLUENCERS, loadInfluencersFromServer)
            })
        }
    }

    function displayRecentLinks() {
        setIsLoadingFinished(prev => ({
            ...prev,
            links: false
        }))
        setRecentActivitiesTab(HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS)
    }

    function displayRecentShorts() {
        setIsLoadingFinished(prev => ({
            ...prev,
            shorts: false
        }))
        setRecentActivitiesTab(HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS)
    }

    function displayRecentInfluencers() {
        setIsLoadingFinished(prev => ({
            ...prev,
            influencers: false
        }))
        setRecentActivitiesTab(HOME_PAGE_RECENT_ACTIVITIES_TABS.INFLUENCERS)
    }

    function renderMobileNoRecentLinks() {
        return <NoItemsFound
            image={<BiLink className='home-page-no-recent-activity-image' />}
            title={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_LINKS_TITLE')}
            subtitle={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_LINKS_SUBTITLE')}
            buttonText={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_LINKS_BUTTON_TEXT')}
            addItemButtonClickHandler={createLink}
            isEmbeddedIcon={true}
            showButton={userDetails?.permissions?.isActiveInfluencer}
        />
    }

    function renderMobileNoRecentShorts() {
        return <NoItemsFound
            image={<ShortsImage className='home-page-no-recent-activity-image' />}
            title={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_SHORTS_TITLE')}
            subtitle={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_SHORTS_SUBTITLE')}
            buttonText={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_SHORTS_BUTTON_TEXT')}
            addItemButtonClickHandler={createShort}
            isEmbeddedIcon={true}
            showButton={userDetails?.permissions?.isActiveInfluencer}
        />
    }

    function renderMobileNoRecentInfluencers() {
        return <NoItemsFound
            image={<NoInfluencersFoundImage className='home-page-no-recent-activity-image' />}
            title={t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_INFLUENCERS_TITLE')}
            subtitle={isFreeUserAndTrialEnded() ? t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_FREE_USER_SUBTITLE') : t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_SUBTITLE')}
            buttonText={isFreeUserAndTrialEnded() ? t('INFLUENCERS_PAGE_TOPBAR_ADD_INFLUENCER_BUTTON_FREE_SUBSCRIPTION_TEXT') : t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_ADD_INFLUENCER_BUTTON_TEXT')}
            addItemButtonClickHandler={isFreeUserAndTrialEnded() ? upgradePlan : createInfluencer}
            isEmbeddedIcon={true}
            showButton={userDetails?.permissions?.isActiveInfluencer}
            buttonStyles={{
                backgroundColor: isFreeUserAndTrialEnded() ? 'black' : 'white',
                color: isFreeUserAndTrialEnded() ? 'white' : 'black'
            }}
        />
    }

    function renderMobileRecentLinks() {
        return mobileRecentActivitiesItems.links.map(link => <LinkItem
            key={link?.linkId}
            linkId={link?.linkId}
            image={link?.image}
            defaultImage={link?.defaultImage}
            views={link?.views}
            revenue={link?.revenue}
            createdAt={link?.createdAt}
            currency={link?.currency}
            isLinkExpired={link?.isLinkExpired}
            title={link?.title}
            influencerUsername={link?.influencerUsername}
            onDeleteLink={loadLinksFromServer}
            onUpdateLink={loadLinksFromServer}
        />)
    }

    function renderMobileRecentShorts() {
        return mobileRecentActivitiesItems.shorts.map(short => <ShortItem
            key={short?.linkId}
            shortId={short?.linkId}
            image={short?.image}
            defaultImage={short?.defaultImage}
            views={short?.views}
            checkouts={short?.checkouts}
            revenue={short?.revenue}
            createdAt={short?.createdAt}
            currency={short?.currency}
            isLinkExpired={short?.isLinkExpired}
            title={short?.title}
            url={short?.shoppingRedirectUrl}
            urlType={short?.urlType}
            influencerUsername={short?.influencerUsername}
            onDeleteLink={loadShortsFromServer}
            onUpdate={loadShortsFromServer}
        />)
    }

    function renderMobileRecentInfluencers() {
        return mobileRecentActivitiesItems.influencers.map(influencer => <InfluencerItem
            key={influencer?.username}
            image={influencer?.image}
            username={influencer?.username}
            isLoadingAnalytics={isLoadingInfluencersAnalytics}
            clicks={influencersAnalytics?.find(influencerAnalytics => influencerAnalytics?.username === influencer?.username)?.totalClicks}
            sales={influencersAnalytics?.find(influencerAnalytics => influencerAnalytics?.username === influencer?.username)?.totalSales}
            onDelete={loadInfluencersFromServer}
            onUpdate={loadInfluencersFromServer}
        />)
    }

    function renderRecentActivityItem(item) {
        switch (recentActivitiesTab) {
            case HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS:
                return <LinkItem
                    key={item?.linkId}
                    linkId={item?.linkId}
                    image={item?.image}
                    defaultImage={item?.defaultImage}
                    views={item?.views}
                    createdAt={item?.createdAt}
                    title={item?.title}
                    influencerUsername={item?.influencerUsername}
                    onDeleteLink={loadLinksFromServer}
                />

            case HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS:
                return <ShortItem
                    key={item?.linkId}
                    shortId={item?.linkId}
                    image={item?.image}
                    defaultImage={item?.defaultImage}
                    views={item?.views}
                    checkouts={item?.checkouts}
                    revenue={item?.revenue}
                    createdAt={item?.createdAt}
                    currency={item?.currency}
                    isLinkExpired={item?.isLinkExpired}
                    title={item?.title}
                    url={item?.shoppingRedirectUrl}
                    urlType={item?.urlType}
                    influencerUsername={item?.influencerUsername}
                    onDeleteLink={loadShortsFromServer}
                />

            default:
                return <InfluencerItem
                    key={item?.username}
                    image={item?.image}
                    username={item?.username}
                    isLoadingAnalytics={isLoadingInfluencersAnalytics}
                    clicks={influencersAnalytics?.find(influencerAnalytics => influencerAnalytics?.username === item?.username)?.totalClicks}
                    sales={influencersAnalytics?.find(influencerAnalytics => influencerAnalytics?.username === item?.username)?.totalSales}
                />
        }
    }

    function seeAllLinks() {
        history.push(LINKS_PAGE_URL)
    }

    function seeAllShorts() {
        history.push(SHORTS_PAGE_URL)
    }

    function seeAllInfluencers() {
        history.push(INFLUENCERS_PAGE_URL)
    }

    function seeAll() {
        switch (recentActivitiesTab) {
            case HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS:
                seeAllLinks()
                break
            case HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS:
                seeAllShorts()
                break
            default:
                seeAllInfluencers()
                break
        }
    }

    function renderSecondaryButtons() {
        return <>
            {
                userDetails?.permissions?.isActiveInfluencer && <div className={`${isMobile ? 'mobile-home-page-header-button' : 'home-page-header-button'} home-page-header-create-short-button`} onClick={createShort}>
                    <ShortsImage className='home-page-header-button-image' />
                    <div className={isMobile ? "mobile-home-page-header-button-text" : "home-page-header-button-text"}>{t('HOME_PAGE_CREATE_SHORT_BUTTON_TEXT')}</div>
                </div>
            }
            {
                !isFreeUserAndTrialEnded() && userDetails?.isAdmin && <div className={`${isMobile ? 'mobile-home-page-header-button' : 'home-page-header-button'} home-page-header-create-influencer-button`} onClick={createInfluencer}>
                    <FiUsers className='home-page-header-button-image' />
                    <div className={isMobile ? "mobile-home-page-header-button-text" : "home-page-header-button-text"}>{t('HOME_PAGE_CREATE_INFLUENCER_BUTTON_TEXT')}</div>
                </div>
            }
        </>
    }

    function upgradePlan() {
        history.push(PRICING_PLANS_PAGE_URL)
    }

    function getNoItemsData() {
        switch (recentActivitiesTab) {
            case HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS: return {
                title: t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_LINKS_TITLE'),
                buttonText: t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_LINKS_BUTTON_TEXT'),
                image: <BiLink className='home-page-no-recent-activity-image' />,
                buttonStyles: null,
                onButtonClick: createLink
            }

            case HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS: return {
                title: t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_SHORTS_TITLE'),
                buttonText: t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_SHORTS_BUTTON_TEXT'),
                image: <ShortsImage className='home-page-no-recent-activity-image' />,
                buttonStyles: null,
                onButtonClick: createShort
            }

            default: return { // influencers
                title: t('HOME_PAGE_RECENT_ACTIVITIES_NO_RECENT_INFLUENCERS_TITLE'),
                subtitle: !isProFeatureAnabled() ? t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_FREE_USER_SUBTITLE') : t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_SUBTITLE'),
                buttonText: !isProFeatureAnabled() ? t('INFLUENCERS_PAGE_TOPBAR_ADD_INFLUENCER_BUTTON_FREE_SUBSCRIPTION_TEXT') : t('INFLUENCERS_ITEM_NO_INFLUENCERS_FOUND_ADD_INFLUENCER_BUTTON_TEXT'),
                image: <NoInfluencersFoundImage className='home-page-no-recent-activity-image' />,
                buttonStyles: {
                    backgroundColor: !isProFeatureAnabled() ? 'black' : 'white',
                    color: !isProFeatureAnabled() ? 'white' : 'black'
                },
                onButtonClick: !isProFeatureAnabled() ? upgradePlan : createInfluencer
            }
        }
    }

    function renderItemsList() {
        if (
            recentActivitiesTab === HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS && !isLoadingFinished.links ||
            recentActivitiesTab === HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS && !isLoadingFinished.shorts ||
            recentActivitiesTab === HOME_PAGE_RECENT_ACTIVITIES_TABS.INFLUENCERS && !isLoadingFinished.influencers
        ) {
            return
        }

        if (recentActivitiesItems.length > 0) {
            return recentActivitiesItems.map(item => renderRecentActivityItem(item))
        }

        const noItemsData = getNoItemsData()
        return <NoItemsFound
            image={noItemsData.image}
            title={noItemsData.title}
            subtitle={noItemsData.subtitle}
            buttonText={noItemsData.buttonText}
            addItemButtonClickHandler={noItemsData.onButtonClick}
            isEmbeddedIcon={true}
            buttonStyles={noItemsData.buttonStyles}
            showButton={userDetails?.isAdmin || userDetails?.permissions?.isActiveInfluencer}
        />
    }

    function renderLoaderContainer(isLoading) {
        return isLoading && <div className={isMobile ? "mobile-home-page-recent-activities-content-display-loader-container" : "home-page-recent-activities-content-display-loader-container"}>
            <Loader styles={{
                width: isMobile ? '20px' : '30px',
                height: isMobile ? '20px' : '30px',
                position: 'absolute',
                inset: 0,
                margin: 'auto'
            }} />
        </div>
    }

    function onTaskClick(task) {
        setExpandedTask(task)
    }

    function hasRemainingTasks() {
        return (
            [null, undefined, ''].includes(userDetails?.businessLogo) ||
            userDetails?.theme?.isInitial ||
            userDetails?.settings?.shippingAndReturnsUrl === '' ||
            !hasUTMParams() ||
            !hasAnalyticsIDs()
        )
    }

    function isMobileActiveInfluencer() {
        return isMobile &&
            !userDetails?.isAdmin &&
            userDetails?.permissions?.isActiveInfluencer
    }

    function closeContentDialog() {
        setContentDialogState(prev => ({
            ...prev,
            isOpen: false
        }))
    }

    return (
        <div className={`${isMobile ? 'mobile-home-page-container' : 'home-page-container'} ${!hasShopifyStore() ? 'no-store-connected' : ''}`}>
            {
                hasShopifyStore() ? <>
                    {(!isMobile || userDetails?.permissions?.isActiveInfluencer || userDetails?.isAdmin) && <div className="home-page-header">
                        {
                            !isMobile && <div className="home-page-logo-header">
                                {
                                    userDetails?.businessLogo ? <ImageBox
                                        image={userDetails?.businessLogo}
                                        className='home-page-logo'
                                        showBorder={true}
                                        roundImage={true}
                                    /> : <div className="home-page-logo-placeholder">
                                        {
                                            userDetails?.isAdmin ? (userDetails?.username ? userDetails?.username[0] : '') : (userDetails?.influencerUsername ? userDetails?.influencerUsername[0] : '')
                                        }
                                    </div>
                                }
                                {
                                    userDetails?.isAdmin ? (userDetails?.username && <div className="home-page-header-username">@{userDetails?.username}</div>) : (userDetails?.influencerUsername && <div className="home-page-header-username">@{userDetails?.influencerUsername}</div>)
                                }
                            </div>
                        }
                        {
                            isFreeUser() && !isMobile && ((userDetails?.isAdmin && isFreeUserAndTrialPastLastWeek()) || isFreeUserAndTrialEnded()) && <div className='home-page-free-trial-alert-message-container'>
                                <AlertMessage
                                    title={userDetails.freeTrialDaysLeft > 0 ? t('HOME_PAGE_FREE_TRIAL_WARNING_TITLE', {daysLeft: userDetails.freeTrialDaysLeft}) : t('HOME_PAGE_FREE_TRIAL_ENDED_TITLE')}
                                    message={userDetails.freeTrialDaysLeft > 0 ? t('HOME_PAGE_FREE_TRIAL_WARNING_MESSAGE') : (userDetails?.isAdmin ? t('HOME_PAGE_FREE_TRIAL_ENDED_MESSAGE') : t('HOME_PAGE_FREE_TRIAL_ENDED_INFLUENCER_MESSAGE'))}
                                    buttonText={userDetails?.isAdmin && t('HOME_PAGE_FREE_TRIAL_UPGRADE_BUTTON_TEXT')}
                                    onButtonClick={upgradePlan}
                                    type={userDetails.freeTrialDaysLeft > 0 ? ALERT_MESSAGE_TYPES.WARNING : ALERT_MESSAGE_TYPES.ERROR }
                                />
                            </div> 
                        }
                        <div className={isMobile ? "mobile-home-page-header-create-buttons" : "home-page-header-create-buttons"} style={{ flexDirection: isMobileActiveInfluencer() && 'row' }}>
                            {
                                userDetails?.permissions?.isActiveInfluencer && <div className={`${isMobile ? 'mobile-home-page-header-button' : 'home-page-header-button'} home-page-header-create-link-button`} onClick={createLink} style={{ width: isMobileActiveInfluencer() && '60%', minWidth: isMobileActiveInfluencer() && 0 }}>
                                    <BiLink className='home-page-header-button-image' />
                                    <div className="home-page-header-button-text">{t('HOME_PAGE_CREATE_LINK_BUTTON_TEXT')}</div>
                                </div>
                            }
                            {
                                isMobile ? <div className="mobile-home-page-header-create-buttons-row" style={{ width: isMobileActiveInfluencer() && '40%' }}>
                                    {renderSecondaryButtons()}
                                </div> : <>
                                    {renderSecondaryButtons()}
                                </>
                            }
                        </div>
                    </div>}
                    {
                        (userDetails?.isAdmin && hasRemainingTasks()) && <HomePageSection
                            title={t('HOME_PAGE_TODO_LIST_SECTION_TITLE')}
                            description={t('HOME_PAGE_TODO_LIST_SECTION_DESCRIPTION')}
                        >
                            <TodoList
                                tasks={taskList}
                                expandedTaskName={expandedTask}
                                onTaskClick={onTaskClick}
                            />
                        </HomePageSection>
                    }
                    <div className="home-page-recent-activities">
                        {
                            isMobile ? <>
                                <MobileRecentActivitySection
                                    title={t('MOBILE_HOME_PAGE_RECENT_ACTIVITIES_TITLE', { displayName: HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS.toLowerCase() })}
                                    buttonText={t('MOBILE_HOME_PAGE_RECENT_ACTIVITIES_SEE_ALL_BUTTON_TEXT')}
                                    onButtonClick={seeAllLinks}
                                >
                                    {renderLoaderContainer(mobileRecentActivitiesLoadingState.links)}
                                    {
                                        isLoadingFinished.links && (
                                            mobileRecentActivitiesItems.links.length > 0 ? <div className="mobile-home-page-recent-activities-content mobile-home-page-recent-instants">
                                                {renderMobileRecentLinks()}
                                            </div> : <div className="mobile-home-page-recent-activities-content-no-items">
                                                {renderMobileNoRecentLinks()}
                                            </div>
                                        )
                                    }
                                </MobileRecentActivitySection>
                                <MobileRecentActivitySection
                                    title={t('MOBILE_HOME_PAGE_RECENT_ACTIVITIES_TITLE', { displayName: HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS.toLowerCase() })}
                                    buttonText={t('MOBILE_HOME_PAGE_RECENT_ACTIVITIES_SEE_ALL_BUTTON_TEXT')}
                                    onButtonClick={seeAllShorts}
                                >
                                    {renderLoaderContainer(mobileRecentActivitiesLoadingState.shorts)}
                                    {
                                        isLoadingFinished.shorts && (
                                            mobileRecentActivitiesItems.shorts.length > 0 ? <div className="mobile-home-page-recent-activities-content mobile-home-page-recent-shorts">
                                                {renderMobileRecentShorts()}
                                            </div> : <div className="mobile-home-page-recent-activities-content-no-items">
                                                {renderMobileNoRecentShorts()}
                                            </div>
                                        )
                                    }
                                </MobileRecentActivitySection>
                                {
                                    userDetails?.isAdmin && <MobileRecentActivitySection
                                        title={t('MOBILE_HOME_PAGE_RECENT_ACTIVITIES_TITLE', { displayName: HOME_PAGE_RECENT_ACTIVITIES_TABS.INFLUENCERS.toLowerCase() })}
                                        buttonText={t('MOBILE_HOME_PAGE_RECENT_ACTIVITIES_SEE_ALL_BUTTON_TEXT')}
                                        onButtonClick={seeAllInfluencers}
                                    >
                                        {renderLoaderContainer(mobileRecentActivitiesLoadingState.influencers)}
                                        {
                                            isLoadingFinished.influencers && (
                                                mobileRecentActivitiesItems.influencers.length > 0 ? <div className="mobile-home-page-recent-activities-content mobile-home-page-recent-influencers">
                                                    {renderMobileRecentInfluencers()}
                                                </div> : <div className="mobile-home-page-recent-activities-content-no-items">
                                                    {renderMobileNoRecentInfluencers()}
                                                </div>
                                            )
                                        }
                                    </MobileRecentActivitySection>
                                }
                            </> : <>
                                <HomePageSection
                                    title={t('HOME_PAGE_RECENT_ACTIVITIES_TITLE')}
                                    description={t('HOME_PAGE_RECENT_ACTIVITIES_DESCRIPTION')}
                                >
                                    <div className="home-page-recent-activities-content">
                                        <div className="home-page-recent-activities-content-header">
                                            <div className={`home-page-recent-activities-content-header-tab ${recentActivitiesTab === HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS ? 'selected' : ''}`} onClick={displayRecentLinks}>{t('HOME_PAGE_RECENT_ACTIVITIES_TAB_LINKS')}</div>
                                            <div className={`home-page-recent-activities-content-header-tab ${recentActivitiesTab === HOME_PAGE_RECENT_ACTIVITIES_TABS.SHORTS ? 'selected' : ''}`} onClick={displayRecentShorts}>{t('HOME_PAGE_RECENT_ACTIVITIES_TAB_SHORTS')}</div>
                                            {userDetails?.isAdmin && <div className={`home-page-recent-activities-content-header-tab ${recentActivitiesTab === HOME_PAGE_RECENT_ACTIVITIES_TABS.INFLUENCERS ? 'selected' : ''}`} onClick={displayRecentInfluencers}>{t('HOME_PAGE_RECENT_ACTIVITIES_TAB_INFLUENCERS')}</div>}
                                        </div>
                                        <div className="home-page-recent-activities-content-body">
                                            <div className={`home-page-recent-activities-content-display ${(!isLoadingDisplayContent && recentActivitiesItems?.length === 0) ? 'no-items' : ''}`}>
                                                <div className={`home-page-recent-activities-content-display-items ${recentActivitiesTab.toLowerCase()} ${(!isLoadingDisplayContent && recentActivitiesItems.length === 0) ? 'no-items' : ''}`}>
                                                    {renderItemsList()}
                                                </div>
                                                {renderLoaderContainer(isLoadingDisplayContent)}
                                            </div>
                                            {recentActivitiesItems.length > 0 && <div className="home-page-recent-activities-content-see-all-button" onClick={seeAll}>{t('HOME_PAGE_RECENT_ACTIVITIES_SEE_ALL_BUTTON_TEXT', { displayName: recentActivitiesTab.toLowerCase() })}</div>}
                                        </div>
                                    </div>
                                </HomePageSection>
                            </>
                        }
                        {
                            userDetails?.isAdmin && <HomePageSection
                                title={t('HOME_PAGE_RECENT_ORDERS_TITLE')}
                                description={!isMobile && t('HOME_PAGE_RECENT_ORDERS_SUBTITLE')}
                            >
                                <StoreOrdersTable />
                            </HomePageSection>
                        }
                    </div>
                </> : <ShopifyConnect hasShopifyStore={false} />
            }
            <AddLInkFlow
                onAddLinkFlowDone={() => reloadAfterCreation(HOME_PAGE_RECENT_ACTIVITIES_TABS.INSTANTS, loadLinksFromServer)}
                trigger={addFlowTrigger}
                setTrigger={setAddFlowTrigger}
            />
            <ContentDialog
                isDialogOpen={contentDialogState.isOpen}
                handleDialogClose={closeContentDialog}
                mode={VIEW_MODES.CREATE}
                contentType={contentDialogState.contentType}
                onSaveSuccess={contentDialogState.onSaveSuccess}
            />
            {
                isMobile && userDetails.isAdmin && isFreeUser() && isLoadingFinished.links && userDetails?.recentActivitiesData?.links.length < 3 && <FloatingButton icon={<EventAvailableIcon/>}text={t("DASHBOARD_TOPBAR_BOOK_AN_EXPERT_BUTTON_TEXT")} clickFunction={() => window.open('https://calendly.com/shopeaks-support/15min','_blank')}/>
            }
        </div>
    )
}